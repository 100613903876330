<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Affiliate Payout Request" />
        <div class="loader">
          <Wave v-if="loading || updating" width="50px" color="#d9534f" />
        </div>
      </div>
      <md-table
        v-model="payouts.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N" md-numeric>{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Reference">{{
            item.reference
          }}</md-table-cell>
          <md-table-cell md-label="Amount">{{
            item.amount | formatMoney
          }}</md-table-cell>
          <md-table-cell md-label="Date Created">{{
            item.created_at | formatDate
          }}</md-table-cell>
          <md-table-cell md-label="User">{{
            item.user ? item.user.first_name + " " + item.user.last_name : "--"
          }}</md-table-cell>

          <md-table-cell md-label="Status">
            <div
              class="badge"
              :class="{
                success: item.status == 'approved',
                danger: item.status == 'disapproved',
              }"
            >
              {{ item.status }}
            </div></md-table-cell
          >
          <md-table-cell md-label="Actions">
            <md-button
              v-if="item.status == 'pending'"
              :disabled="updating"
              @click="approve(item.id, item.amount)"
              :loading="loading || updating"
              md-menu-trigger
              class="bggreen"
              >Approve Payment</md-button
            >
            <div v-else>Payment Approved</div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="payouts.mdPage"
        :records="payouts.mdCount"
        :per-page="payouts.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("affiliate");
export default {
  name: "affiliate",
  data() {
    return {
      updating: false,
      exporting: false,
      search: "",
      approval_status: "",
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getAffiliatesRequests", "approvePayout"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getAffiliate(data);
    },
    async changeAffiliateStatus(id, status) {
      try {
        this.updating = true;
        await this.updateAffiliateStatus({ approval_status: status, id });
        this.updating = false;
        this.toast("success", "Affiliate Status Updated ");
      } catch (err) {
        this.updating = false;
        console.error(err);
      }
    },
    async approve(id, amount) {
      let conf = confirm(
        `Are you sure you want to transfer ₦${amount} to affiliate wallet?`
      );
      if (conf) {
        try {
          this.updating = true;
          await this.approvePayout(id);
          this.toast("success", "Payout Approved");
          this.getAffiliatesRequests();
          this.updating = false;
        } catch (err) {
          this.toast("success", "Opps, Something went wrong");
          this.updating = false;
        }
      }
    },
    async exportExcel() {
      this.exporting = true;
      await this.$store.dispatch("auth/export", { model: "affiliate" });
      this.exporting = false;
    },

    async submitServices() {
      this.updating = true;
      var data = new FormData();
      if (this.approval_status == "") return;
      data.append("approval_status", this.approval_status);
      data.append("services", this.multi_services);
      data.append("_method", "PATCH");

      await this.updateServiceStatus(data);
      this.updating = false;
      this.showS = false;
      this.toast("success", "Status updated");
    },

    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
  },
  created() {},
  mounted() {
    this.getAffiliatesRequests();
  },
  computed: {
    ...mapGetters(["payouts", "loading"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: green;
  }

  &.danger {
    background: red;
  }
}

.s-con {
  .head,
  .body .s-items {
    display: flex;
    align-items: center;

    > div {
      width: calc(100% / 3);
    }
  }
  .head {
    font-weight: 700;
  }

  .body .s-items .s-item {
    padding: 10px 0px;
  }
}
</style>
